import { styled } from '@mui/material/styles';

const MaterialRoot = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
}));

export default MaterialRoot;